import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import useMutation from '../../hooks/useMutation'

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const [getLangs] = useMutation('languages')
  const [languages, setLanguages] = useState([])
  const [ready, setReady] = useState(null)

  /**
   * This function transform the resource to a object
   * @param {*} items
   * @returns
   */
  const trnasformResource = (items) => {
    const translation = {}
    items.map((i) => (translation[i.key] = i.value))
    return translation
  }

  /**
   * This function change the language
   * @param {string} lang
   */
  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  /**
   * This function get the languages from the api
   * @returns {object} resources - The resources for i18n with the languages
   */
  const getLanguages = async () => {
    const resources = {}
    const { success, data } = await getLangs({
      method: 'get'
    })

    if (success && data) {
      setLanguages(data?.data?.items)
      data?.data?.items.forEach((i) => {
        resources[i.code] = {
          translation: trnasformResource(i.translation)
        }
      })
    }
    return resources
  }

  /**
   * This function init the i18n
   */
  const getInitialI18next = async () => {
    const resources = await getLanguages()
    i18n
      .use(LanguageDetector)
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: resources,
        fallbackLng: 'es',
        debug: false,
        keySeparator: false
      })
    setReady(true)
  }

  /**
   * This function get the language from the i18n
   * @returns {string} language - The language
   */
  const getLanguage = () => {
    const language = i18n.language
    if (i18n?.language && language.includes('-')) {
      return language.split('-')[0]
    }
    return language
  }
  useEffect(() => {
    getInitialI18next()
  }, [])

  return (
    <LanguageContext.Provider
      value={{
        languages,
        onChangeLanguage,
        language: getLanguage()
      }}
    >
      {ready ? children : null}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useLanguage = () => useContext(LanguageContext)
export { useLanguage, LanguageProvider, i18n }
