import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useGetUsersInfo from '../../../../infrastructure/hooks/useUsersManagement'
import Loader from '../../../../components/Loader'
import UserInfoCard from '../UserInfoCard'
import Button from '../../../../components/Button'
import ChangePasswordModal from '../ChangePasswordModal'
import UserLogsAggrid from '../UserLogsAggrid'
import Paragraphs from '../../../../components/Paragraphs'
import ChangeEmailModal from '../ChangeEmailModal/ChangeEmailModal'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { useTranslationsCRUD } from '../../../../infrastructure/hooks/useTranslationsCRUD/useTranslationsCRUD'
import { actionSetEditUserField } from '../../../../infrastructure/redux/actions/userTab'
import useUser from '../../../../infrastructure/hooks/useUser'
import VerifyEmailModal from '../VerifyEmailModal'

const UserDetail = ({
  id,
  isAdmin,
  isSuperAdmin,
  isEditing,
  setIsEditing,
  isSaving,
  setIsSaving,
  changesUserField,
  handleSave,
  userData,
  setUserData
}) => {
  const {
    getSingleUser,
    loading,
    updateUser,
    getSupplantLink,
    getCompleteProfile,
    assingRole,
    getUserRolesList,
    removeRoleUser
  } = useGetUsersInfo()

  const { t } = useTranslation()
  const { showMessage } = useFlashMessage()
  const [isCompleteProfile, setIsCompleteProfile] = useState(false)
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false)
  const [openModalVerifyEmail, setOpenModalVerifyEmail] = useState(false)
  const [openModalBi, setOpenModalBi] = useState(false)
  const [perPage, setPerPage] = useState(25)
  const { translate, loading: loadingTranslate } = useTranslationsCRUD()
  const { user } = useUser()
  const dispatch = useDispatch()

  /**
   * It loads the user's data
   * @async
   * @returns {void}
   */
  const loadUserData = async () => {
    const { data, success, status } = await getSingleUser(id)
    if (success) {
      // Check if the user has completed their pharmacy SEPA state
      const findPharmacySepaState = data.userPharmacySepaData[0]
      const completed =
        findPharmacySepaState.value !== null
          ? findPharmacySepaState.value.includes('completed')
          : false
      setIsCompleteProfile(completed)
      setUserData(data)
    } else {
      showMessage({
        type: 'alert',
        message: t('Ha ocurrido un error en el servidor')
      })
    }
  }

  /**
   * Retrieves the supplant link for the user and opens it in a new browser tab.
   * @async
   * @returns {void}
   */
  const supplantUser = async () => {
    const { success, error, link } = await getSupplantLink(id)
    if (success && link) {
      window.open(link, '_blank')
    } else if (error) {
      showMessage({
        type: 'alert',
        message: error.message ? error.message : error
      })
    }
  }

  /**
   * It opens a new tab with the complete profile link in order to complete the user's profile
   * @async
   * @param id - the user's id
   * @returns {void}
   */
  const completeProfile = async () => {
    const { success, error, link } = await getCompleteProfile(userData.id)
    if (success && link) {
      window.open(link, '_blank')
    } else if (error) {
      showMessage({
        type: 'alert',
        message: error.message ? error.message : error
      })
    }
  }

  /**
   * It cancel the state the edit mode
   */
  const handleCancel = () => {
    setIsEditing(false)
    setIsSaving(false)
    dispatch(actionSetEditUserField([]))
  }

  /**
   * Fetches the user's data
   */
  useEffect(async () => {
    const dataFecth = async () => {
      await loadUserData()
    }

    dataFecth()
  }, [id])

  useEffect(() => {
    if (isSaving) {
      handleSave(id)
    }
  }, [isSaving])

  /**
   * This function is used to check if the user has a specific role
   * @param {array} roles
   * @returns {boolean}
   */
  const hasUserRole = (roles) => {
    return roles.some((role) => user?.roles?.includes(role))
  }

  /**
   * This constant is used to check if the pharmacy is validated
   * @returns {boolean}
   */
  const isPharmacyValidated = !!userData?.userPharmacyData?.find(
    (data) => data?.label === 'Estado'
  )?.value

  // While loading any data, it shows the loader
  if (loading || loadingTranslate || isSaving || !userData) {
    return <Loader mode={'modal'} />
  } else {
    return (
      <div className='tw-flex tw-flex-col tw-w-full tw-h-full'>
        {openChangePasswordModal && (
          <ChangePasswordModal
            onRequesClose={() => setOpenChangePasswordModal(false)}
            id={id}
            isAdmin={userData?.isAdmin}
            personalData={userData?.userPersonalData}
            updateUser={updateUser}
            loading={loading}
          />
        )}
        {openChangeEmailModal && (
          <ChangeEmailModal
            onRequestClose={() => setOpenChangeEmailModal(false)}
            id={id}
            isAdmin={userData?.isAdmin}
            personalData={userData}
            updateUser={updateUser}
            loading={loading}
            setUserData={setUserData}
          />
        )}
        {openModalVerifyEmail && (
          <VerifyEmailModal
            onRequestClose={() => setOpenModalVerifyEmail(false)}
            id={id}
            personalData={userData}
            updateUser={updateUser}
            loading={loading}
            setUserData={setUserData}
          />
        )}

        <div className='tw-flex tw-justify-start tw-items-center tw-p-3 tw-w-full'>
          <div className='tw-flex tw-gap-4 tw-w-full'>
            {isEditing ? (
              <>
                <Button
                  label={t('guardar datos')}
                  uppercase
                  onClick={() => setIsSaving(true)}
                  mode={'secondary'}
                />

                <Button
                  label={t('cancelar')}
                  uppercase
                  onClick={handleCancel}
                  mode={'secondary'}
                />
              </>
            ) : hasUserRole(['Admin', 'Support', 'SuperAdmin']) ? (
              <Button
                label={t('modificar datos')}
                uppercase
                onClick={() => setIsEditing(true)}
                mode={'secondary'}
              />
            ) : null}
            <Button
              label={t('Cambiar emails')}
              uppercase
              onClick={() => setOpenChangeEmailModal(true)}
              disabled={isEditing}
            />
            <Button
              label={t('Cambiar contraseña')}
              uppercase
              onClick={() => setOpenChangePasswordModal(true)}
              disabled={isEditing}
            />
            <Button
              label={t('refrescar datos')}
              uppercase
              onClick={() => loadUserData()}
              disabled={isEditing}
            />
            <Button
              label={t('suplantar')}
              uppercase
              onClick={() => supplantUser()}
              disabled={loading || isEditing}
            />
            {!isCompleteProfile && (
              <Button
                label={'Completar perfil'}
                uppercase
                onClick={() => completeProfile()}
                mode={'secondary'}
                disabled={isEditing}
              />
            )}
          </div>
        </div>

        {/* CARDS */}
        <div className='tw-flex tw-w-full tw-justify-between'>
          <div className='tw-p-3 tw-flex tw-justify-start tw-flex-wrap tw-gap-2 tw-h'>
            <UserInfoCard
              title={t('Datos generales')}
              fields={userData?.userPersonalData}
              currentUserId={id}
              isEditing={isEditing}
              isSaving={isSaving}
              userData={userData}
              changesUserField={changesUserField}
              setUserData={setUserData}
              isSuperAdmin={hasUserRole(['Admin', 'Support', 'SuperAdmin'])}
              updateUser={updateUser}
              setOpenModalVerifyEmail={setOpenModalVerifyEmail}
            />
            <UserInfoCard
              title={t('Datos de contacto')}
              fields={userData?.userContact}
              currentUserId={id}
              isEditing={isEditing}
              isSaving={isSaving}
              userData={userData}
              setUserData={setUserData}
              isSuperAdmin={isSuperAdmin}
            />
            <UserInfoCard
              title={t('Datos de la farmacia')}
              fields={userData?.userPharmacyData}
              currentUserId={id}
              isEditing={isEditing && isPharmacyValidated}
              isSaving={isSaving}
              isPharmacy={true}
              userData={userData}
              setUserData={setUserData}
              isSuperAdmin={isSuperAdmin}
            />

            <UserInfoCard
              title={t('Datos de pago')}
              fields={userData?.userPharmacySepaData}
            />

            {userData?.userUnnefarData?.map((data) => (
              <UserInfoCard
                key={data.id}
                title={`Unnefar - ${
                  data.find((col) => col.label === 'Tipo')?.value
                }`}
                fields={data}
              />
            ))}

            <UserInfoCard
              title={t('Administración de usuario')}
              fields={userData?.userAdminData}
              assingRole={assingRole}
              getUserRolesList={getUserRolesList}
              currentUserId={id}
              userData={userData}
              setUserData={setUserData}
              isSuperAdmin={isSuperAdmin}
              updateUser={updateUser}
              isEditing={isEditing}
            />
          </div>
        </div>
        <div className='tw-w-full tw-flex tw-justify-between  tw-bg-secondary tw-text-white tw-px-2'>
          <Paragraphs weight='bold' size='xl'>
            {t('Log de acciones del usuario')}
          </Paragraphs>
          {userData?.userLogs?.length > 25 && (
            <div className='tw-flex tw-w-56 tw-justify-between  tw-items-center'>
              <Paragraphs className='tw-self-center' weight='bold'>
                {t('Registros por página')}:
              </Paragraphs>
              <select
                onChange={(e) => setPerPage(e.target.value)}
                id='page-size'
                className='tw-cursor-pointer tw-border-black tw-border tw-rounded tw-text-black'
              >
                <option value='25' defaultValue>
                  25
                </option>
                {userData?.userLogs?.length > 25 && (
                  <option value='50'>50</option>
                )}
                {userData?.userLogs?.length > 100 && (
                  <option value='100'>100</option>
                )}
                {userData?.userLogs?.length > 500 && (
                  <option value='500'>500</option>
                )}
                {userData?.userLogs?.length > 1000 && (
                  <option value='1000'>1000</option>
                )}
              </select>
            </div>
          )}
        </div>

        <div className='tw-w-full tw-h-auto'>
          <UserLogsAggrid
            userLogs={userData?.userLogs}
            perPage={perPage}
            translate={translate}
            userId={id}
          />
        </div>
      </div>
    )
  }
}

UserDetail.propTypes = {
  id: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  isClickClose: PropTypes.bool.isRequired,
  changesUserField: PropTypes.array,
  handleSave: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired
}

export default UserDetail
